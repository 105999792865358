export enum GeoType {
  IP,
  GPS,
  Travel,
}

export interface Geo {
  city: string;
  country: string;
  latitude: string;
  longitude: string;
  type: GeoType;
  selected: boolean;
}
